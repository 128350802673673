/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Badge, Row, Col, Modal,
} from 'reactstrap';
import {
  Intl, DetailCard, LocalTable, TableEditBtn, TableActionsBtns,
  Form, FormActionsBtns, TextField, AddBtn,
} from 'components';
import { formatPhone, handlePromise } from 'utils';
import GestService from 'api/Guest';
import UserService from 'api/User';
import CustomerService from 'api/Customer';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import getStatusColor from 'config/Constants/StatusColor';
import { required, email, composeValidators } from 'config/InputErrors';
import GuestDetail from '../Detail';

const statusColor = getStatusColor( 'bookingGuests' );
const emailAlreadyUsedError = ['This email is already in use for this booking', 'The email already exists in this reservation.'];

const renderActions = ( {
  data, reInviteGest, resetPassword, onConvertToCustomer, onEdit,
} ) => {
  const actions = [];

  if ( _.get( data, 'user.lastLogin' ) ) {
    actions.push(
      {
        label: 'reInviteEmail',
        onClick: ( ) => reInviteGest( data ),
      },
    );
    actions.push( {
      label: 'edit_email',
      onClick: ( ) => onConvertToCustomer( data ),
    } );
    actions.push( {
      label: 'guest.resetPassword',
      onClick: ( ) => resetPassword( data ),
    } );
  } else if ( _.get( data, 'user.email' ) ) {
    actions.push( {
      label: 'edit_email',
      onClick: ( ) => onConvertToCustomer( data ),
    } );
    actions.push( {
      label: 'guest.resetPassword',
      onClick: ( ) => resetPassword( data ),
    } );
  } else {
    actions.push( {
      label: 'convertToCustomer',
      onClick: ( ) => onConvertToCustomer( data ),
    } );
  }

  if ( _.get( data, 'travelReportStatus' ) !== 'APPROVED' ) {
    actions.push(
      {
        label: 'edit',
        onClick: ( ) => onEdit( data ),
      },
    );
  }
  return (
    <TableActionsBtns
      actions={actions}
    />
  );
};

const GuestList = ( ( {
  data, onReloadData, toggleLoading, toggleErrorAlert, toggleInfoAlert, handleOpen, onEdit,
} ) => {
  const [detailModalOpened, setDetailModalOpened] = useState( false );
  const [modalEmail, setModalEmail] = useState( false );
  const [guestId, setGuestId] = useState( '' );
  const detailData = useRef( {} );

  const openDetailModal = useCallback( async ( entity ) => {
    detailData.current = entity;
    setDetailModalOpened( !detailModalOpened );
  }, [detailModalOpened] );

  const closeDetailModal = useCallback( () => {
    setDetailModalOpened( false );
  }, [] );

  const reInviteGest = async ( { id, user } ) => {
    toggleLoading( true );
    const [error, response] = await handlePromise(
      GestService.reInviteGuest(
        id,
        { email: user.email },
      ),
    );
    toggleLoading( false );
    if ( !response.ok ) return toggleErrorAlert( error );

    toggleInfoAlert( 'successreInviteEmail' );
  };

  const resetPassword = async ( { user } ) => {
    toggleLoading( true );
    const [error, response] = await handlePromise(
      UserService.requestResetPassword(
        user.email,
      ),
    );
    if ( !response.ok ) return toggleErrorAlert( error );
    const [errorLog, responseLog] = await handlePromise(
      CustomerService.getResetLog(
        user.id,
      ),
    );
    if ( !responseLog.ok ) return toggleErrorAlert( errorLog );
    toggleLoading( false );
    toggleInfoAlert( 'guest.mailSent' );
  };

  const onConvertToCustomer = ( value ) => {
    setGuestId( value );
    setModalEmail( true );
  };

  const submit = async ( values ) => {
    toggleLoading( true );

    const id = _.get( guestId, 'id' );
    const userEmail = _.get( guestId, 'user.email' );
    const [error, response] = userEmail
      ? await handlePromise(
        GestService.updateEmail(
          id,
          { ...values, bookedAccommodation: data.id },
        ),
      )
      : await handlePromise(
        GestService.convertToCustomer(
          id,
          values,
        ),
      );
    toggleLoading( false );
    if ( !response.ok ) {
      const err = emailAlreadyUsedError.includes( _.get( error, 'email', error ) )
        ? 'email_already_used'
        : error;
      return err;
    }
    setGuestId();
    setGuestId( );
    setModalEmail( false );
    onReloadData();

    toggleInfoAlert( 'successreInviteEmail' );
  };

  return (
    <DetailCard
      classNames={{ card: 'mt-3', header: 'py-3 border-0', body: 'pt-0 pb-2 px-0' }}
      header={(
        <Row className="justify-content-stretch align-items-center">
          <Col>
            <h3 className="m-0">
              <Intl id="guests" />
            </h3>
          </Col>
          {
           !_.get( data, 'checkinComplete' ) && (
           <Col className="text-right">
             <AddBtn title="newGuest" onClick={handleOpen} />
           </Col>
           )
          }
        </Row>
    )}
    >
      <LocalTable
        onlyTable
        manualPagination
        data={data.guests || []}
        columns={[
          {
            Header: 'name',
            accessor: 'name',
            Cell: ( { cell } ) => (
              <TableEditBtn
                title={`${cell.value} ${cell.row.original.firstLastname || ''}`}
                onClick={() => openDetailModal( cell.row.original )}
              />
            ),
          },
          {
            Header: 'email',
            accessor: 'user.email',
          },
          {
            Header: 'phoneNumber',
            accessor: 'user.phoneNumber',
            Cell: ( { cell } ) => ( cell.value ? formatPhone( cell.value ) : '' ),
          },
          {
            Header: 'travelReport',
            accessor: 'travelReportStatus',
            Cell: ( { cell } ) => (
              <div className="text-center">
                {cell.value ? <Badge color={statusColor( cell.value )} pill><Intl id={cell.value} /></Badge> : <Badge color={statusColor( 'PENDING' )} pill><Intl id="PENDING" /></Badge>}

                {cell.row.original.travelReportFile ? (
                  <a
                    href={cell.row.original.travelReportFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Badge color="outline-green" pill>
                      <Intl id="download" />
                    </Badge>

                  </a>
                ) : null}
              </div>
            ),
          },
          {
            Header: 'mobileApp',
            accessor: 'user',
            Cell: ( { cell } ) => ( cell.value
              ? (
                <div className="text-center">
                  {cell.value.lastLogin && (
                    <Badge color={statusColor( 'appMobileDownloaded' )} pill><Intl id="downloaded" /></Badge>
                  )}
                  {!cell.value.lastLogin && (
                    <Badge color={statusColor( 'appMobilePending' )} pill><Intl id="invited" /></Badge>
                  )}
                </div>
              ) : null ),
          },
          {
            accessor: 'user.email',
            Header: null,
            maxWidth: 65,
            Cell: ( { cell } ) => renderActions( {
              data: cell.row.original,
              reInviteGest,
              resetPassword,
              onConvertToCustomer,
              onEdit,
            } ),
          },

        ]}
      />

      <Modal
        isOpen={modalEmail}
        toggle={() => setModalEmail( true )}
        className="modal-dialog-centered"
      >
        <div className="modal-body">
          <Form
            onSubmit={submit}
            showErrorsInFooter
            body={() => (
              <TextField
                field="email"
                label="email"
                validate={composeValidators( required, email )}
              />
            )}
            footer={(
              <Row>
                <Col className="d-flex justify-content-end">
                  <FormActionsBtns onClickCancel={() => setModalEmail( false )} />
                </Col>
              </Row>
            )}
          />
        </div>
      </Modal>

      <GuestDetail
        data={detailData.current}
        open={detailModalOpened}
        onClose={closeDetailModal}
        onReloadData={onReloadData}
      />
    </DetailCard>
  );
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( React.memo( GuestList ) );
